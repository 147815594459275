import React, { useEffect } from "react";
import "./table.css";
import {
  Link,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../../../../api";
import ScaleLoader_ from "../../../components/ScaleLoader";
import { AssessementContext } from "../../../AssessmentContext";
import { toast } from "react-hot-toast";
import CheckCompatibility from "./../../../components/CheckCompatibility";
import UnlockAssessmentModal from "./unlockAssessmentModal";
import { useSelector, useDispatch } from "react-redux";
import {
  clearFailedQuestions,
  clearFlaggedQuestion,
  clearRecentActiveExamData,
  flushSubmittedAnswers
} from "../../../../../../features/candidate-exam/candidate-exam-slice";

const CandidateExams = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const previousRoute = location.state?.from;
  const { assessment, setAssessment } = React.useContext(AssessementContext);

  const [showModal, setShowModal] = React.useState(false);
  const { startedExams } = useSelector((state) => state.candidateExam);
  const dispatch = useDispatch();
  //const activeCandidateExams = JSON.parse(sessionStorage.getItem('active-candidate-exam'))?.assessments  
  // const getExamsMutation = useMutation(api.getExams, {
  //   onSuccess: (data) => {
  //     setAssessment({ ...assessment, viewExams: [...data?.data] });
  //   },
  // });

  const {
    isLoading,
    data: data,
    refetch,
    isError,
    error,
  } = useQuery(
    "get-exams-data",
    async () => {
      const data = await api.getAssesments();
      return data;
    },
    {
      enabled:
        previousRoute !== "/exam/:slug" && previousRoute !== "/rejoin-exam",
      onSuccess: (data) => {
        const my_exams = data?.data?.upcoming_exams.filter((upcoming_exam) => {
          return (
            upcoming_exam?.user_group_id ===
            sessionStorage.getItem("user_group_id")
          );
        });
        setAssessment({ ...assessment, viewExams: my_exams[0]?.assessments });
        // save active user assessments for automatic exam switch
        sessionStorage.setItem(
          "active-upcoming-exams",
          JSON.stringify([
            ...my_exams[0]?.assessments.filter(
              (exam) => exam.completed_at === null
            ),
          ])
        );
      },
    }
  ); 

  /*React.useEffect( () => {
      setAssessment({ ...assessment, viewExams: [...activeCandidateExams] });
     //save active user assessments for automatic exam switch.
      sessionStorage.setItem('active-upcoming-exams', JSON.stringify(
      [...activeCandidateExams.filter(
        exam => exam.completed_at === null
      )]
      ))
  }, [] ) */

  React.useEffect(() => {
    if (!location.pathname.includes("candidate-assessment")) {
     // dispatch(clearRecentActiveExamData());
      sessionStorage.setItem("last_question_viewed", 0);
    }
  }, [location.pathname]);

  React.useEffect(() => {
     //dispatch( flushSubmittedAnswers() )
     dispatch( clearFailedQuestions() )
  }, []);

  /*const getQuestionsmutation = useMutation(api.getExamQuestions, {
    onSuccess: (data) => {
      setAssessment({
        ...assessment,
        assessements: { ...data?.data },
        activeQuestion: { ...data?.data?.questions[0] },
        questionType: data?.data?.questions[0]?.type,
        activeAssessment: {
          ...JSON.parse(window.sessionStorage.getItem("active-assessment")),
        },
      });
      navigate("/candidate/assessments/instructions");
    },
    onError: (errorMessage) => {
      if (errorMessage.toString().includes("400")) {
        toast.error("You are not scheduled to write today");
        return;
      } else if (errorMessage.toString().includes("404")) {
        toast.error("Assessment does not belong to a group");
        return;
      }
      //navigate('/candidate/assessments/instructions');
    },
  });*/

  const startExam = (data, startedExam_ = null) => {
    // clear recent flagged questions
    dispatch(clearFlaggedQuestion());

    /*getQuestionsmutation.mutate({
      assessmentId: data.assessment_id,
      groupId: sessionStorage.getItem("user_group_id"),
    });*/
    window.sessionStorage.setItem("active-assessment-id", data?.assessment_id);
    window.sessionStorage.setItem("active-assessment", JSON.stringify(data));
    window.sessionStorage.setItem(
      "submit-assessment-payload",
      JSON.stringify({
        user_group_id: sessionStorage.getItem("user_group_id"),
        assessment_id: data.assessment_id,
      })
    );
    window.sessionStorage.setItem("time_left", (data?.time_left));
    window.sessionStorage.setItem("duration",  (data?.duration));
    window.sessionStorage.setItem(
      "ql",
      data?.number_of_questions_left ? data?.number_of_questions_left : 0
    );
    navigate('/candidate/assessments/instructions')
  };

  const standaloneExam = sessionStorage.getItem("view");

  useEffect(() => {
    if (standaloneExam === "viewStandaloneExam") {
      document.querySelector("#sidebar").style.display = "none";
      sessionStorage.setItem(
        "user_group_id",
        JSON.parse(sessionStorage.getItem("user"))?.user_group_id
      );
    }
  }, [standaloneExam]);

  //check if  incoming candidate is via link
  React.useEffect(() => {
    if (previousRoute === "/exam/:slug" || previousRoute === "/rejoin-exam") {
      const StandAloneExamData = JSON.parse(sessionStorage.getItem("user"));
      setAssessment({
        ...assessment,
        viewExams: StandAloneExamData?.exam_data?.length
          ? [...StandAloneExamData?.exam_data]
          : [],
      });
      /* save active user assessments for automatic exam switch */
      sessionStorage.setItem(
        "active-upcoming-exams",
        JSON.stringify(
          StandAloneExamData?.exam_data?.length
            ? [
                ...StandAloneExamData?.exam_data.filter(
                  (exam) => exam.completed_at === null
                ),
              ]
            : []
        )
      );
    }
  }, []);

  return (
    <>
      {" "}
      { /*getQuestionsmutation.isLoading ||*/ isLoading ? (
        <ScaleLoader_ />
      ) : (
        <div className="bg-white">
          <section className="jumbotron bg-white p-4">
            {standaloneExam !== "viewStandaloneExam" && (
              <>
                <Link
                  to={"/candidate/assessments/view-assessments"}
                  className="text-lg"
                >
                  Exams
                </Link>
                <span className="mx-2">/</span>
                <Link
                  to={"/candidate/assessments/view-assessments"}
                  className="text-lg"
                >
                  View Exams
                </Link>
                <span className="mx-2">/</span>
                <Link
                  to={`/candidate/assessments/candidate-exams/${id}`}
                  className="text-lg text-customGreen"
                >
                  Candidate exam
                </Link>
              </>
            )}

            <div className="text-white bg-darkBlue mt-8 mb-5 py-5 px-4 mx-1 rounded-lg">
              Candidate Exam &nbsp;{" "}
              {assessment?.viewExams?.length
                ? `(${assessment?.viewExams?.length})`
                : null}
            </div>
            <div className="md:hidden">
              {assessment?.viewExams?.length &&
              window.matchMedia("(max-width: 500px)").matches
                ? assessment?.viewExams?.map((exam, id) => {
                    const examStarted =
                      exam?.number_of_questions_left !== exam?.number_of_questions &&
                      exam?.number_of_questions_left !== null &&
                      exam?.completed_at === null;

                    const last_question_viewed =
                      parseInt(exam?.number_of_questions) -
                      parseInt(exam?.number_of_questions_left);

                    return (
                      <ul
                        key={id}
                        className="border border-gray-300 rounded-lg mb-8 p-0"
                      >
                        <li className="flex justify-between py-4 px-4">
                          <span className="text-lg">
                            Assessment Title:&nbsp;
                          </span>
                          <span className="text-lg">
                            {exam?.assessment_title}
                          </span>
                        </li>
                        <li className="flex justify-between bg-gray-100 py-4 px-4">
                          <span className="text-lg">Category Title:&nbsp;</span>
                          <span className="text-lg">
                            {exam?.category_title}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                          <span className="text-lg">Duration:&nbsp;</span>
                          <span className="text-lg">
                            { ( examStarted || exam?.time_left < exam?.duration ) ? (
                              <>
                                <del className="text-lg text-red-500">
                                  {(exam?.duration / 60) + " minutes"}
                                </del>
                                <span className="text-lg ml-5">
                                  {Math.floor((exam?.time_left / 60))}
                                  &nbsp;minutes &nbsp; Left
                                </span>
                              </>
                            ) : (
                              exam?.duration < exam?.time_left ? 
                               (exam?.time_left / 60) + " minutes"
                              : (exam?.duration / 60) + " minutes"
                            )}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4 bg-gray-100">
                          <span className="text-lg">Status:&nbsp;</span>
                          <span className="text-lg">
                            {exam?.completed_at !== null ? (
                              <span className="text-green-500 text-lg">
                                {" "}
                                Completed{" "}
                              </span>
                            ) : examStarted ? (
                              <span className=" text-lg text-customGreen">
                                Started{" "}
                              </span>
                            ) : (
                              <span className="text-yellow-500 text-lg">
                                Not Started{" "}
                              </span>
                            )}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                          <span className="text-lg">
                            No Of Questions:&nbsp;
                          </span>
                          <span className="text-lg">
                            {examStarted ? (
                              <>
                                <del className="text-lg text-red-500">
                                  {exam?.number_of_questions}
                                </del>
                                <span className="text-lg ml-5">
                                  {exam?.number_of_questions_left}{" "}
                                  &nbsp;questions &nbsp; Left
                                </span>
                              </>
                            ) : (
                              exam?.number_of_questions
                            )}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4 bg-gray-100">
                          <span className="text-lg">Action:&nbsp;</span>
                          <span className="text-lg">
                            {exam?.completed_at !== null ? (
                              "------------- "
                            ) : examStarted ? (
                              <button
                                className="bg-customGreen text-white py-4 px-[43px] rounded-lg"
                                onClick={() => {
                                  startExam(exam, examStarted);
                                  sessionStorage.setItem(
                                    "last_question_viewed",
                                    last_question_viewed
                                  );
                                  sessionStorage.setItem(
                                    "assessment-id",
                                    exam?.assessment_id
                                  );
                                }}
                              >
                                Continue
                              </button>
                            ) : exam?.completed_at === null ? (
                              <button
                                onClick={() => {
                                  if (exam?.assessment_lock_status === 1) {
                                    document.querySelector(
                                      `#modal-${exam.assessment_id}`
                                    ).style.display = "flex";
                                  } else setShowModal(true);
                                }}
                                className=" bg-customGreen text-white py-4 px-5 rounded-lg"
                              >
                                {exam?.assessment_lock_status === 1
                                  ? "Check  compatibility"
                                  : "Start Exam"}
                              </button>
                            ) : (
                              "------------"
                            )}
                            {exam?.assessment_lock_status === 1 ? (
                              <div>
                                {
                                  <CheckCompatibility
                                    startExam={() => startExam(exam)}
                                    id={`modal-${exam.assessment_id}`}
                                  />
                                }
                                
                              </div>
                            ) : (
                              <div>
                                {showModal && (
                                  <UnlockAssessmentModal
                                    // refetch={refetch}
                                    closeModal={setShowModal}
                                    // getExamsMutation={getExamsMutation}
                                    // id={id}
                                  />
                                )}
                              </div>
                            )}
                          </span>
                        </li>
                      </ul>
                    );
                  })
                : null}
            </div>

            {assessment?.viewExams?.length ? (
              <div className="flex flex-col  overflow-x-auto m-hidden">
                <div className="sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                      <table className="border border-gray-200 min-w-full text-left text-sm font-light">
                        <thead className="shadow font-medium dark:border-neutral-500">
                          <tr>
                            <th scope="col" className="px-6 py-6">
                              S/N
                            </th>
                            <th scope="col" className="px-6 py-6">
                              Assessment Title
                            </th>
                            <th scope="col" className="px-6 py-6">
                              Category Title
                            </th>
                            <th scope="col" className="px-6 py-6">
                              Duration
                            </th>
                            <th scope="col" className="px-6 py-6">
                              Status
                            </th>
                            <th scope="col" className="px-2 md:px-6 py-6">
                              No Of Questions
                            </th>
                            <th scope="col" className="px-6 py-6">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {assessment?.viewExams?.length
                            ? assessment?.viewExams?.map((exam, id) => {
                                const examStarted =
                                  exam?.number_of_questions_left !==
                                    exam?.number_of_questions &&
                                  exam?.number_of_questions_left !== null &&
                                  exam?.completed_at === null;

                                const last_question_viewed =
                                  parseInt(exam?.number_of_questions) -
                                  parseInt(exam?.number_of_questions_left);

                                return (
                                  <tr
                                    key={exam?.assessment_id}
                                    className="border-b border-gray-200"
                                  >
                                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                                      {" "}
                                      {id + 1}{" "}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {exam?.assessment_title}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {exam?.category_title}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      { ( examStarted || exam?.time_left < exam?.duration ) ? 
                                      (
                                        <>
                                          <del className="text-lg text-red-500">
                                            {( exam?.duration / 60) + " minutes"}
                                          </del>
                                          <span className="text-lg ml-5">
                                            {Math.floor((exam?.time_left / 60))}
                                            &nbsp;minutes Left
                                          </span>
                                        </>
                                      ) : 
                                        (
                                          exam?.duration < exam?.time_left ? 
                                           (exam?.time_left / 60) + " minutes"
                                          : (exam?.duration / 60) + " minutes"
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {exam?.completed_at !== null ? (
                                        <span className="text-green-500 text-lg">
                                          {" "}
                                          Completed{" "}
                                        </span>
                                      ) : examStarted ? (
                                        <span className=" text-lg text-customGreen">
                                          Started{" "}
                                        </span>
                                      ) : (
                                        <span className="text-yellow-500 text-lg">
                                          Not Started{" "}
                                        </span>
                                      )}
                                    </td>

                                    <td className="whitespace-nowrap pl-12 md:pl-[26px] pr-6 py-4">
                                      {examStarted ? (
                                        <>
                                          <del className="text-lg text-red-500">
                                            {exam?.number_of_questions}
                                          </del>
                                          <span className="text-lg ml-5">
                                            {exam?.number_of_questions_left}{" "}
                                            &nbsp;questions Left
                                          </span>
                                        </>
                                      ) : (
                                        exam?.number_of_questions
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {exam?.completed_at !== null ? (
                                        "-----------"
                                      ) : examStarted ? (
                                        <button
                                          id="continue-exam"
                                          className="bg-customGreen text-white py-4 px-[43px] rounded-lg"
                                          onClick={() => {
                                            startExam(exam, examStarted);
                                            sessionStorage.setItem(
                                              "last_question_viewed",
                                              last_question_viewed
                                            );
                                          }}
                                        >
                                          Continue
                                        </button>
                                      ) : exam?.completed_at === null ? (
                                        <button
                                          id="check"
                                          onClick={() => {
                                            if (
                                              exam?.assessment_lock_status === 1
                                            ) {
                                              document.querySelector(
                                                `#modal-${exam.assessment_id}`
                                              ).style.display = "flex";
                                               
                                            } else {
                                              setShowModal(true);
                                              
                                            }
                                          }}
                                          className=" bg-customGreen text-white py-4 px-5 rounded-lg"
                                        >
                                          {exam?.assessment_lock_status === 1
                                            ? "Check  compatibility"
                                            : "Start Exam"}
                                        </button>
                                      ) : (
                                        "------------"
                                      )}
                                      {exam?.assessment_lock_status === 1 ? (
                                        <div>
                                          {
                                            <CheckCompatibility
                                              startExam={() => startExam(exam)}
                                              id={`modal-${exam.assessment_id}`}
                                            />
                                          }                                           
                                        </div>
                                      ) : (
                                        <div>
                                          {showModal && (
                                            <UnlockAssessmentModal
                                              // refetch={refetch}
                                              closeModal={setShowModal}
                                              // getExamsMutation={getExamsMutation}
                                              // id={id}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center hidden">
                <h5 className="bi bi-book text-center text-[60px] mt-8"></h5>
                <p className="text-center text-xl">
                  There is no any exam setup for this group yet.
                </p>
              </div>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default CandidateExams;
