import React from 'react'
import { useParams } from "react-router-dom";

const ExamResult = () => {

  const { id } = useParams()  

  return (
    <div>
        { id }
        <h1 className="border border-darkBlue bg-darkBlue py-5 px-3 rounded-md text-white">
          Candidate Exam Result
        </h1>        
    </div>
  )
}

export default ExamResult