import React, { useState, useEffect, useContext } from "react";

import { useQuery } from "react-query";
import { CandidateContext } from "./CandidateContext";
import {
  Navigate,
  Route,
  Routes as CandidateRoutes,
  useLocation,
  Link,
} from "react-router-dom";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import useWindowDimensions from "../../hooks/useWindowSize";

//ROUTES
import Assessment from "./assessments/Assessment";
import Dashboard from "./dashboard/Dashboard";
import Documentation from "./documentation/Documentation";
import ExamHistory from "./examHistory/ExamHistory";
import Settings from "./settings/Settings";
import Notifications from "./notifications/Notifications";
import Profile from "./profile/Profile";
import Vacancies from "./vacancies/Vacancies";
import { pageRoutes } from "../../PageRoutes";
import ExamResult from "./exam-result/ExamResult";
import LostHere from "../../assets/icons/lost.svg";
import FeedBack from "./FeedBack";
import NotFound from './../../notFound';

import NoAccess from './../../noAccess';

const CandidateRoutes_ = () => {
  const [candidateContext, setCandidateContext] = useState({});
  const [isOpen, setIsOpen] = useState(
    //hide sidebar in mobile by default
    window.matchMedia("(max-width: 700px)").matches ? false : true
  );
  const [currentPageID, setCurrentPageID] = useState(0);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const disabled = process.env.REACT_APP_DISABLE_ROUTE 

  const toggleSidebar = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const setSelectedPageID = (id) => {
    setCurrentPageID(id);
  };

  const standaloneExam = sessionStorage.getItem("view");

  useEffect(() => {
    if (
      !location.pathname.includes("candidate-assessment") &&
      standaloneExam !== "viewStandaloneExam"
    ) {
      document.querySelector("#sidebar").style.display = "flex";
      if (window.matchMedia("(max-width: 700px)").matches) {
        document.querySelector(".mobile-navbar").style.display = "block";
      }
    }
  }, [location.pathname]);

  
  return (
    <React.Fragment>
      {width < 768 && (
        <Header sidebarToggle={toggleSidebar} windowWidth={width} />
      )}
      <div className="flex layout-wrapper">
        <Sidebar
          pages={pageRoutes}
          setPage={setSelectedPageID}
          currentPage={currentPageID}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          windowWidth={width}
        />
        <div
          className="p-2 md:p-5"
          id="parent-container"
          style={{
            flex: "4",
            backgroundColor: "#f2f3f7",
            height: width < 768 ? "calc(100vh - 43px)" : "100vh",
            overflowY: "scroll",
          }}
        >
          <CandidateContext.Provider
            value={{ candidateContext, setCandidateContext }}
          >
            <CandidateRoutes>
              <Route
                path="/"
                element={<Navigate to={"/candidate/dashboard"} />}
              />
              <Route path="/dashboard" element={disabled === "disable-route" ? <NoAccess/>: <Dashboard width={width} />} />
              <Route
                path="/assessments/*"
                element={<Assessment isOpen={isOpen} setIsOpen={setIsOpen} />}
              />
              <Route path="/documentation" element={<Documentation />} />
              <Route path="/exam-history" element={<ExamHistory />} />
              <Route path="/exam-result/:id" element={<ExamResult />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/profile" element={disabled === "disable-route" ? <NoAccess />: <Profile />} />
              if () {
                
              }
              <Route path="/profile/:uid1/:uid2" element={disabled === "disable-route" ? <NoAccess />: <Profile />} />
              <Route path="/vacancies" element={<Vacancies />} />
              <Route path="/feedback" element={<FeedBack />} />

              {/*  404 page  */}
              <Route
                path="*"
                element={
                  <div class="container z-10 flex items-center justify-between h-screen px-6 pt-32 mx-auto md:pt-0">
                    <div class="container relative flex flex-col-reverse items-center justify-between px-6 mx-auto lg:flex-row">
                      <div class="w-full mb-16 text-center md:mb-8 lg:text-left">
                        <h1 class="mt-12 font-sans text-5xl font-light text-center text-gray-700 lg:text-left lg:text-8xl md:mt-0">
                          Sorry, this page isn&#x27;t available
                        </h1>
                        {/* <button class="px-6 py-2 mt-16 text-lg font-light transition duration-200 ease-in bg-yellow-300 border-2 border-gray-700 w-36 hover:bg-yellow-400 focus:outline-none"> */}
                        <button className="mt-20 border border-[#88a848] p-2 w-[200px] rounded-lg">
                          <Link
                            to="/candidate/dashboard"
                            className="text-[#88a848] text-2xl font-medium"
                          >
                            Go back home
                          </Link>
                        </button>
                        {/* </button> */}
                      </div>
                      <div class="relative block w-full max-w-md mx-auto md:mt-0 lg:max-w-2xl">
                        <img src={LostHere} />
                      </div>
                    </div>
                  </div>
                }
              />
            </CandidateRoutes>
          </CandidateContext.Provider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateRoutes_;
