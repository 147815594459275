import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

import { api } from "../../api";

import { toast } from "react-hot-toast";

import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import { Link } from 'react-router-dom';

function FeedBack() {
  const customToast = useCustomToast();
  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");
  const [answers, setAnswers] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
  });

  

  const handleRadioChange = (question, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: answer,
    }));
  };

  



  const ratingLabel = (rating) => {
    if (rating == 0.5 || rating == 1) {
      return "Bad";
    } else if (rating == 1.5 || rating == 2) {
      return "Fair";
    } else if (rating == 2.5 || rating == 3) {
      return "Good";
    } else if (rating == 3.5 || rating == 4) {
      return "Very Good";
    } else {
      return "Excellent";
    }
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
    
    let label = "";
    if (newRating == 0.5 || newRating == 1) {
      label = "Bad";
    } else if (newRating == 1.5 || newRating == 2) {
      label = "Fair";
    } else if (newRating == 2.5 || newRating == 3) {
      label = "Good";
    } else if (newRating == 3.5 || newRating == 4) {
      label = "Very Good";
    } else {
      label = "Excellent";
    }

   
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let content = {
      content:text,
      rating:rating
      // content:answers, rating:rating, message:text
    };
   

    try {
      toast.loading("loading...");
      const res = await api.sendFeedback(content);
      toast.remove();
      customToast.success(res.message);
    } catch (error) {
            customToast.error(error.response.data.error);
    }
  };

  return (
    <div className="max-w-[1072px] text-darkBlue bg-white mt-20 p-4 md:p-16 rounded-lg">
       <div className="flex justify-end items-end">
          <Link to="" className="text-[#F35B24] text-lg">
            Skip
          </Link>
          </div>
      <div className="flex justify-center">
        <div className="flex flex-col mb-10">
        <div className="flex justify-between items-center">
        <div>
        <h3 className="text-primary font-semibold text-xl mb-6 text-center">
            Assessment Completed Successfully{" "}
          </h3> 
        </div>
         
        </div>
          <p className="font-semibold text-base text-darkBlue mb-9">
            You hve successfully completed your assessment on Numeracy.{" "}
          </p>
          <p className="font-semibold text-base text-darkBlue mb-6">
            Kindly provide feedback to help us improve on your experience
          </p>
          <p className="text-primary text-2xl font-bold text-center">
            How will you rate your experience
          </p>
        </div>
      </div>
      
      <form onSubmit={handleSubmit}>
      <div className="flex justify-center mb-16">
        <div className="">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={44}
            isHalf={true}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
          <div className="text-center mt-2 text-gray-600">
            {rating > 0 && `${ratingLabel(rating)}`}
          </div>
        </div>
      </div>
      <div className="">
        <h3 className="mb-6 text-xl font-bold ">
          Tell Us more about Your Experience
        </h3>
        <div className="w-3/4 flex flex-col">
          <div className="flex justify-between mb-6">
            <div className="text-base font-medium">
              Did you find the platform easy to navigate?
            </div>
            <div className="">
              <div className="flex gap-x-20 items-center">
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q1"
                    value="yes"
                    checked={answers.q1 === "yes"}
                    onChange={() => handleRadioChange("q1", "yes")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  Yes
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q1"
                    value="no"
                    checked={answers.q1 === "no"}
                    onChange={() => handleRadioChange("q1", "no")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <div className="text-base font-medium">
              Did the questions align with the test's focus?
            </div>
            <div className="">
              <div className="flex gap-x-20 items-center">
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q2"
                    value="yes"
                    checked={answers.q2 === "yes"}
                    onChange={() => handleRadioChange("q2", "yes")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  Yes
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q2"
                    value="no"
                    checked={answers.q2 === "no"}
                    onChange={() => handleRadioChange("q2", "no")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <div className="text-base font-medium">
              Were you able to complete the test within the provided time?
            </div>
            <div className="">
              <div className="flex gap-x-20 items-center">
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q3"
                    value="yes"
                    checked={answers.q3 === "yes"}
                    onChange={() => handleRadioChange("q3", "yes")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  Yes
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q3"
                    value="no"
                    checked={answers.q3 === "no"}
                    onChange={() => handleRadioChange("q3", "no")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <div className="text-base font-medium">
              Did you feel supported by the instructions given before and during
              the tests?
            </div>
            <div className="">
              <div className="flex gap-x-20 items-center">
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q4"
                    value="yes"
                    checked={answers.q4 === "yes"}
                    onChange={() => handleRadioChange("q4", "yes")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  Yes
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q4"
                    value="no"
                    checked={answers.q4 === "no"}
                    onChange={() => handleRadioChange("q4", "no")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-6">
            <div className="text-base font-medium">
              Were the questions clear and straightforward?
            </div>
            <div className="">
              <div className="flex gap-x-20 items-center">
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q5"
                    value="yes"
                    checked={answers.q5 === "yes"}
                    onChange={() => handleRadioChange("q5", "yes")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  Yes
                </div>
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="q5"
                    value="no"
                    checked={answers.q5 === "no"}
                    onChange={() => handleRadioChange("q5", "no")}
                    className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                  />
                  No
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <h3 className="text-base font-medium mb-6">
          Is there anything you will like us to improve upon?
        </h3>
        <div className="flex gap-x-56 items-end">
          <textarea
            className="w-2/4 h-32 p-2 bg-[#F2F3F7] border border-lightGrey rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
            placeholder="Leave your comment here."
            value={text}
            onChange={(e) => setText(e.target.value)}
          ></textarea>
          <div className="">
            <button type="submit" className="bg-darkBlue px-8 py-3 font-bold text-sm text-white rounded-lg">
              Submit
            </button>
          </div>
        </div>
      </div>
      </form>
    </div>
  );
}

export default FeedBack;
