import React from 'react'
import { Link } from 'react-router-dom'
import Chart from './components/Chart'
import Range from 'react-simple-range'
import './index.css'
import AptitudeTest from "./components/AptitudeTest";
 
const AssessmentResult = () => {
    return (
        <div>
                <section className='jumbotron bg-white p-4'>
                    <Link to={'/candidate/assessments/view-assessments'} className='text-sm'>
                        Assessement
                    </Link>
                    <span className='mx-2'>/</span>
                    <Link to={'/candidate/assessments/instructions'} className='text-sm text-customGreen'>
                        Result
                    </Link>    
                    <div className='mt-8 py-5 bg-customGreen px-5 text-white rounded-lg'>
                         <h5 className='text-2xl flex justify-between'>
                             January 2023 flash assessment
                            <div>
                                <span className='text-xl flex items-center'> <i className='text-2xl bi bi-clock-history mr-2'></i> 5:00:00 </span>
                                <p> Answered 12 of 50 questions </p>
                            </div>
                         </h5>
                         <div className='text-gray-900 bg-white mt-4 py-3 px-3 rounded-lg'>
                            <p className='mb-3 text-md'> Date: &nbsp;23 march 2023</p>                          
                            <p className='mb-3 text-md'> Start Time:&nbsp; 01:30pm</p>
                            <p className='mb-3 text-md'> End Time: &nbsp; 02:40am</p>
                         </div>
                    </div>                                    
                </section>          
                <section className='md:flex justify-between mt-4'>
                     <div className='w-[35%] bg-white p-3'>
                        <h5 className='text-lg'> Aptitute Test</h5>
                        <div
                            className="flex items-center justify-between p-2 "
                            style={{ flex: "1" }}
                        >
                        <p className="text-4xl text-darkBlue font-bold">
                            <span className='text-3xl'>50/</span> <span>100</span>
                        </p>
                        <div style={{ height: "190px", width: "190px", overflow: "hidden" }}>
                            <Chart />
                        </div>
                        </div>
                        <div>
                            <span className='block mb-2 text-green-500'>Correct: &nbsp;50</span>
                            <span className='block mb-2 text-yellow-500'>Partially Correct:&nbsp; 10</span>
                            <span className='block mb-2 text-red-500'>Incorrect:&nbsp; 30</span>
                            <span className='block mb-2 text-gray-500'>Unanswered:&nbsp; 5</span>
                        </div>
                     </div>                                
                     <div className='w-[63%] bg-white p-4'>
                       <h5 className='text-lg'> Mandatory Trait</h5>
                        <div className='flex justify-between items-center mt-4'>
                              <div className='30% flex items-center'>
                                Agreeableness <span className='ml-2 py-2 px-4 bg-green-100 rounded-lg'> 93% </span>
                              </div>
                              <div className='w-[70%] slider-wrapper-main'>
                                    <Range 
                                    min = {0}
                                    max = {100 }
                                    value = {93}
                                    sliderSize = { 10 }
                                    sliderColor = '#f2f3f7'
                                    trackColor = '#9ab767'
                                    disableThumb = {true}
                                    thumbColor = '#9ab767'
                                    disabled = {true}
                                    />             
                              </div>
                        </div>

                        <div className='flex justify-between items-center mt-4'>
                              <div className='30% flex items-center'>
                                Conscientiousness <span className='ml-2 py-2 px-4 bg-yellow-100 rounded-lg'> 40% </span>
                              </div>
                              <div className='w-[70%] slider-wrapper-main'>
                                    <Range 
                                    min = {0}
                                    max = {100 }
                                    value = {40}
                                    sliderSize = { 10 }
                                    sliderColor = '#f2f3f7'
                                    trackColor = '#f2f555'
                                    thumbColor = '##f2f555'
                                    disableThumb = {true}
                                    disabled = {true}
                                    />             
                              </div>
                        </div>

                        <div className='flex justify-between items-center mt-4'>
                              <div className='30% flex items-center'>
                                Extroversion <span className='ml-2 py-2 px-4 bg-[#40f5f5] rounded-lg'> 70% </span>
                              </div>
                              <div className='w-[70%] slider-wrapper-main'>
                                    <Range 
                                    min = {0}
                                    max = {100 }
                                    value = {70}
                                    sliderSize = { 10 }
                                    sliderColor = '#f2f3f7'
                                    trackColor = ' #40f5f5'
                                    thumbColor = '#40f5f5'
                                    disableThumb = {true}
                                    disabled = {true}
                                    />             
                              </div>
                        </div>

                        <div className='flex justify-between items-center mt-4'>
                              <div className='30% flex items-center'>
                                Openness <span className='ml-2 py-2 px-4 bg-red-100 rounded-lg'> 60% </span>
                              </div>
                              <div className='w-[70%] slider-wrapper-main'>
                                    <Range 
                                    min = {0}
                                    max = {100 }
                                    value = {60}
                                    sliderSize = { 10 }
                                    sliderColor = '#f2f3f7'
                                    trackColor = '#f76931'
                                    thumbColor = '#9ab767'
                                    disableThumb = {true}
                                    disabled = {true}
                                    />             
                              </div>
                        </div>

                        <div className='flex justify-between items-center mt-4'>
                              <div className='30% flex items-center'>
                                 Stress Tolerance <span className='ml-2 py-2 px-4 bg-blue-100 rounded-lg'> 80% </span>
                              </div>
                              <div className='w-[70%] slider-wrapper-main'>
                                    <Range 
                                    min = {0}
                                    max = {100 }
                                    value = {80}
                                    sliderSize = { 10 }
                                    sliderColor = '#f2f3f7'
                                    trackColor = ' #4726ff'
                                    thumbColor = '#9ab767'
                                    disableThumb = {true}
                                    disabled = {true}
                                    />             
                              </div>
                        </div>
                     </div>                     
                </section>  
                <div className='mt-3 bg-white px-4 py-2'>
                          <h5 className='text-lg mb-3 py-4 rounded-lg bg-gray-200 mt-3 pl-3'> Aptitude Test </h5>
               </div>  
               <AptitudeTest />
       </div>
      )
}

export default AssessmentResult