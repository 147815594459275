 export const updatedSavedAnswerPayload = ( originalArray = [], questionDurations, questions ) => {
    // Create an empty array to store the transformed data
    const transformedData = {
       questions: []
    };
    // Create a map to group answers by question_id
    const answerMap = new Map();

    originalArray.forEach(item => {
       const { question_id, duration, answer_id, answer_text } = item;
       if (!answerMap.has(question_id)) {
          answerMap.set(question_id, {
             duration: 0,
             answers: [],
          });
       }
       answerMap.get(question_id).answers.push({ answer_id, answer_text });
       answerMap.get(question_id).duration = duration;
    });
    // Iterate through the map to create the final structure
    answerMap.forEach((questionData, question_id) => {

      const saved_answers = questionData.answers
      const original_question_answers = questions.filter( q => q?.id === question_id )[0]?.answers
      const saved_answers_ids = new Set(saved_answers.map(ans => ans.answer_id))
       
      const updated_question_answers = original_question_answers.map( answer => ({
         answer_id: answer?.id,
         answer_text: answer?.text,
         status: saved_answers_ids.has(answer.id),
       }));
       
       transformedData.questions.push({
          question_id,          
          answers: updated_question_answers?.filter( ans => ans?.status === true )
       });
    });
   // Create a map for quick lookup based on question id
   const durationMap = new Map(questionDurations.map(duration => [duration.id, duration.duration]));

   const questionsWithDuration = transformedData?.questions.map( question => {
      const questionId = question.question_id;
      const duration = durationMap.get(questionId);
      return  { ...question, duration: duration || 0 /*Default to 0 if duration is not found*/ }
   })

  return {  ...transformedData, questions: questionsWithDuration }
}