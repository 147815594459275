import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import UpcommingAssessment from "./components/UpcommingAssessment";

const ViewAssessement = () => {      

     useEffect(() => {
          window.onbeforeunload = function(event) {
               event.preventDefault();
               event.returnValue = '';
             }
        }, []);

  return (
    <div className='bg-white'>       
        <section className='jumbotron bg-white p-4'>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg'>
                  Exam
            </Link>
            <span className='mx-2'>/</span>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg text-customGreen'>
                 View Exams
            </Link>
            <div className='md:flex md:justify-between items-center mt-8'>
         <p className='text-[13px] md:w-[60%]'>
          Click the  
          "take practice test" button to recieve a practice  exam with random question to
          prepare you better.
        </p>
        <div className='mt-3 sm:mt-0'>
            <Link 
              to={'/candidate/assessments/candidate-practice-test'} 
              className='px-8 py-3 rounded-lg bg-customGreen text-white text-sm'
            //className='px-8 py-3 rounded-lg bg-gray-200  text-sm'
            >
                 Take Practice Test
             </Link> 
        </div>
         </div>         
        </section>                    
         <div className='pt-3 pb-12 px-3'>
             <UpcommingAssessment  />  
          </div>               
    </div>
  )
}

export default ViewAssessement