import React, { useState } from "react";
import { api } from "../../../api";
import { useQuery } from "react-query";
import Applications from "./components/Applications";
import ScaleLoader_ from "../assessments/components/ScaleLoader";
import { Link } from "react-router-dom";
import Card from "./components/Card";
import UpcommingAssessment from "../assessments/pages/view-assessement/components/UpcommingAssessment";
import { AssessementContext } from "../assessments/AssessmentContext";
import { defaultState } from "../assessments/components/defaultState";

const Dashboard = ({ width }) => {
  const [showFilter, hideFilter] = useState(false);
  const [assessment, setAssessment] = useState(defaultState);
   
  const { data, isLoading, isError } = useQuery(
    ["applications"],
    async () => {
      const data = await api.getApplications();
      return data.data;
    },
    { cacheTime: 30000, staleTime: 30000, refetchOnWindowFocus: true }
  );

  const user = JSON.parse(sessionStorage.getItem("user"));

  if (isError) {
    return <p>Error</p>;
  }

  return (
    <React.Fragment>
      <AssessementContext.Provider value={{ assessment, setAssessment }}> 
      {isLoading ? (
        <ScaleLoader_ />
      ) : (
        <>  
          <div 
            className="rounded-md mt-1 bg-white p-4 gap-4"
            style={{
              display: "flex",
              width: "100%",
              // gridTemplateColumns: width > 768 ? "4fr 1.5fr" : "1fr",
              border: "1px solid #ccc",
            }}
          >
            <div className="w-full pt-4 md:pt-0">
              <div className="md:hidden flex justify-end">
                <Link
                  to="/candidate/notifications"
                  className="md:hidden bg-[#6c0064] bi bi-bell text-white py-4 px-12 rounded-lg text-lg"
                >
                  &nbsp; Notifications{" "}
                  <sup className="-mt-3 bg-white text-[#6c0064] rounded-full p-[5px] ml-1">
                    {" "}
                    2+{" "}
                  </sup>
                </Link>
              </div>
              {/*WELCOME SECTION*/}
              <div className="md:flex items-center justify-between w-full">
                <div>
                  <h1
                    className="text-darkBlue"
                    style={{ fontSize: "20px", marginTop: "10px" }}
                  >
                    Welcome {user?.name}!
                  </h1>
                </div>
                <div>
                  <Link
                    to="/candidate/notifications"
                    className="m-hidden bg-[#6c0064] bi bi-bell text-white py-4 px-12 rounded-lg text-lg"
                  >
                    &nbsp; Notifications{" "}
                    <sup className="-mt-3 bg-white font-semibold text-[#6c0064] rounded-full p-[5px] ml-1">
                      {" "}
                      2+{" "}
                    </sup>
                  </Link>
                </div>
              </div>
              <h5 className="pl-2 mt-8 text-xl"> Application Overview </h5>
              <div className="bg-gray-100 p-8 mx-3 mb-12 mt-8 grid grid-cols-1 md:grid-cols-4">
                <Card title={"All Applications"} text={data?.data?.summary?.total} />
                <Card
                  title={"Application Stage"}
                  text={data?.data?.summary?.application_stages}
                  color="text-[#F35B24]"
                />
                <Card
                  title={"Online Test Stage"}
                  text={data?.data?.summary?.virtual_stages}
                  color="text-[#FDB919]"
                />
                <Card
                  title={"Assessment Day Stage"}
                  text={data?.data?.summary?.physical_stages}
                  color="text-[#87AA4B]"
                />
              </div>
              <div className="pb-5 md:flex flex-wrap items-center">
                <span className="text-xl md:mr-4">Stages</span>
                <div className="flex items-center md:ml-3 mt-3 md:mt-0">
                  <button className="w-[20px] rounded-lg h-[20px] bg-[#F35B24]"></button>
                  <span className="bi bi-arrow-right text-xl mx-3"></span>
                  <span className="text-md text-[#F35B24]">
                    {" "}
                    Application Stage{" "}
                  </span>
                </div>
                <div className="flex items-center md:ml-8 mt-3 md:mt-0">
                  <button className="w-[20px] rounded-lg h-[20px] bg-[#FDB919]"></button>
                  <span className="bi bi-arrow-right text-xl mx-3"></span>
                  <span className="text-md text-[#FDB919]">
                    {" "}
                    Online Test Stage{" "}
                  </span>
                </div>
                <div className="flex items-center md:ml-8 mt-3 md:mt-0">
                  <button className="w-[20px] rounded-lg h-[20px] bg-[#87AA4B]"></button>
                  <span className="bi bi-arrow-right text-xl mx-3"></span>
                  <span className="text-md text-[#87AA4B]">
                    {" "}
                    Assessment Day Stage{" "}
                  </span>
                </div>
              </div>
              <div className="mb-20">
                 <UpcommingAssessment />
              </div>
              {/*APPLICATIONS*/}
              <div className="w-full mt-3">
                <header className="bg-darkBlue py-5 px-4 rounded-md mb-4">
                  <h1 className="text-white text-xl">
                    Applications &nbsp;
                    {data?.data?.applications?.data?.length
                      ? `(${data?.data?.applications?.data?.length})`
                      : null}
                  </h1>
                </header>
                <Applications width={width} data={data?.data?.applications} />
              </div>
            </div>
          </div>
        </>
      )}
      </AssessementContext.Provider>
    </React.Fragment>
  );
};

export default Dashboard;
