import React from 'react'

const Card = ({ title, text, color = 'text-gray-900'}) => {
  return (
    <div className='w-full mb-5 md:mb-0 md:w-[200px] py-8 border border-gray-200 rounded-lg bg-white'>
        <p className='text-lg text-center mb-3'>{title}</p>
        <h1 className={`text-5xl text-center font-bold mb-3 mt-2 ${color}`}>{text || '-'}</h1>
    </div>
  )
}

export default Card