import React, { useState, Fragment, useEffect } from "react";
import {
  Routes as AssessmentRouter,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AssessementContext } from "./AssessmentContext";
import { defaultState } from "./components/defaultState";
import ViewAssessement from "./pages/view-assessement/ViewAssessement";
import Instructions from "./pages/instructions/Instructions";
import AssessmentResult from "./pages/assessment-result/AssessmentResult";
import CandidateAssessment from "./pages/candidate-assessment/CandidateAssessment";
import { useTimer } from "react-timer-hook";
import CandidatePracticeTest from "./pages/candidate-practice-test/CandidatePracticeTest";
import CandidateExams from "./pages/view-assessement/components/CandidateExams";

const Assessment = ({ isOpen, setIsOpen }) => {
  const [assessment, setAssessment] = useState(defaultState);
  const location = useLocation();
  const navigate = useNavigate();
  const totalTime = 0;
  const { seconds, minutes, hours, start, restart, totalSeconds, pause } = useTimer({
    expiryTimestamp: totalTime,
    onExpire: () => {
      setAssessment({ ...assessment, isTimedOut: true });
    },
    autoStart: false,
  });

  useEffect(() => {
    /*if (assessment.startAssessment) {
      if (!location.pathname.includes("candidate-assessment")) {
        navigate("/candidate/assessments/candidate-assessment");
      }
    }*/
  }, [location.pathname])

  return (
    <Fragment>
      <section>
        <AssessementContext.Provider value={{ assessment, setAssessment }}>
          <AssessmentRouter>
            <Route
              path="/"
              element={
                <Navigate to={"/candidate/assessments/view-assessments"} />
              }
            />
            <Route path="/view-assessments" element={<ViewAssessement />} />
            <Route
              path="/instructions"
              element={
                <Instructions
                  start={start}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  restart={restart}
                />
              }
            />
            <Route path="/result" element={<AssessmentResult />} />
            <Route
              path="/candidate-assessment"
              element={
                assessment.startAssessment ? (
                  <CandidateAssessment
                    seconds={seconds}
                    hours={hours}
                    minutes={minutes}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    start={start}
                    totalSeconds={totalSeconds}
                    pause = {pause}
                    restart={restart}
                  />
                ) : (
                  <Navigate to={"/candidate/assessments/view-assessments"} />
                )
              }
            />
            <Route path="/candidate-exams/:id" element={<CandidateExams />} />
            <Route path="/candidate-exams/" element={<CandidateExams />} />
            <Route
              path="/candidate-practice-test"
              element={<CandidatePracticeTest />}
            />
          </AssessmentRouter>
        </AssessementContext.Provider>
      </section>
    </Fragment>
  );
};

export default Assessment;
