import React from 'react'

const dummyData = [
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
    { name: 'Speed ex Mar 2023' , type : 'Skill test' , question : 100, startDate : '05 Apr 2023' },
 ]

const AptitudeTest = () => {
  return (
    <>
        <table className="w-full mt-4 bg-white">
          <thead>
            <tr
              className="w-full flex items-end gap-2 justify-between  border border-slate-50 px-3 py-3 rounded-lg text-slate-500 mt-1"              
            >
              <th>
                <input
                  type="checkbox"
                  className="border border-slate-400 outline-none focus:ring-0 rounded mb-1"
                />
              </th>
              <th
                className="text-left text-baseFont"     
                style={{width:40}}            
              >
                Que.Id
              </th>
              <th
                className="text-center text-baseFont"       
                style={{width:110}}           
              >
                Status
              </th>
              <th
                className="text-left text-baseFont"  
                style={{width: 110}}               
              >
                Difficulty
              </th>
              <th
                className="text-left text-baseFont"         
                style={{width: 70}}       
              >
                   Marks
              </th>
              <th
                className="text-left text-baseFont" 
                style={{width: 90}}                
              >
                 Time Spend
              </th>
              <th
                className="text-center text-baseFont"      
                style={{width: 90}}          
              >
                Section
              </th>
              <th
                className="text-center text-baseFont"  
                style={{width: 90}}                    
              >
                Actions
              </th>              
            </tr>
          </thead>
          <tbody>
             { dummyData.map( (data,id) => {
                  return(
                    <tr                 
                    className="w-full flex items-center gap-2 justify-between  p-2 py-3 text-slate-500 mt-1"
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="border border-slate-400 outline-none focus:ring-0 rounded mb-1"
                      />
                    </td>
                    <td
                      className=" text-baseFont"  
                      style={{width:40}}                 
                    >        
                      {id+1}          
                    </td>                                       
                    <td
                      className="text-left text-baseFont"     
                      style={{width: 110}}              
                    >        
                       {( id % 2 === 0) ?
                          <button  className='text-green-500 px-8 py-2 text-center w-[110px] rounded-lg bg-green-200 text-sm'>
                             Correct
                          </button>
                         :
                         <button  className='text-red-500 px-8 py-2 text-center w-[110px] rounded-lg bg-red-200 text-sm'>
                              Incorrect
                          </button>
                      }           
                    </td>
                    <td
                      className=" text-baseFont"  
                      style={{width:120}}                 
                    >        
                         {( id % 2 === 0) ?
                           'Beginner'
                         :
                         'Intermediate'
                      }     
                    </td>   
                    <td
                      className=" text-baseFont"   
                      style={{width: 70}}               
                    >        
                      1/1         
                    </td>
                    <td
                      className="text-left text-baseFont"  
                      style={{width: 90}}                
                    >              
                      00:05:30
                    </td>
                    <td
                      className="text-left pl-3 text-baseFont"  
                      style={{width: 90}}                 
                    >    
                     Quantitative Reasoning
                    </td>                                        
                    <td
                      className="text-left text-baseFont"    
                      style={{width: 90}}                   
                    >       
                      <button className='py-2 border border-gray-200 w-full text-center rounded-lg'>
                          Review
                      </button>
                    </td> 
                  </tr>          
                  )
             } ) }               
          </tbody>
        </table>
    </>
  )
}

export default AptitudeTest