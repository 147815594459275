import React, { useState } from "react";
import AsyncSelect from "react-select/async";

import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";

import DatePicker from "react-datepicker";
import moment from "moment";
import { api } from "../../../../api";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import BioData from "./BioData";
import PhoneInput, {
  parsePhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
useCustomToast;

import { useCustomToast } from "../../../../components/customtoast/CustomToastProvider";
import { ScaleLoader } from "react-spinners";
import { TroubleshootSharp } from "@mui/icons-material";

function EditBiodataModal({ closeModal, biodata, refetch, refetchStatus }) {
  const customToast = useCustomToast();
  const { isLoading, data: states } = useQuery("get-states", api.getStates);
  const [selectedDate, setSelectedDate] = useState(
    biodata?.date_of_birth || null
  );
  const [phoneNumber, setPhoneNumber] = useState(biodata?.phone_number);
  const [name, setName] = useState(biodata?.name);
  const [email, setEmail] = useState(biodata?.email);
  const [gender, setGender] = useState(biodata?.gender);
  // const [dateOfBirth, setDateOfBirth] = useState(biodata?.date_of_birth);
  const [selectedCountry, setSelectedCountry] = useState("NG");
  const [message, setMessage] = useState("");
  const [isError, setError] = useState("");
  const [ageMessage, setAgeMessage] = useState("");

  const [nameValid, setNameValid] = useState(true);
  const [genderValid, setGenderValid] = useState(true);
  const [selectedDateValid, setSelectedDateValid] = useState(true);
  const [selectStateValid, setSelectStateValid] = useState(true);
  const [selectResidenceValid, setSelectResidenceValid] = useState(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [selectState, setSelectedState] = useState(
    biodata?.state_of_origin || ""
  );
  const [selectStateId, setSelectedStateId] = useState(
    biodata?.state_of_origin_id || ""
  );
  const [selectResidence, setSelectedResidence] = useState(
    biodata?.state_of_residence || ""
  );
  const [selectResidenceId, setSelectedResidenceId] = useState(
    biodata?.state_of_residence_id || ""
  );

  const filteredStates = (inputValue) => {
    return states?.data
      .filter((state) =>
        state.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((s) => ({ value: s.id, label: s.name }));
  };

  const loadOptions = (inputValue, callback) => {
    callback(filteredStates(inputValue));
  };

  const validatePhoneNumber = (number) => {
    // Regex pattern for Nigeria and foreign phone numbers
    const nigeriaPattern = /^(\+?234|0)[789]\d{9}$/;
    if (selectedCountry === "NG") {
      return nigeriaPattern.test(number) && number.length <= 14; // Enforce 14 character limit
    } else {
      return foreignPattern.test(number) && number.length <= 14; // Enforce 14 character limit
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setPhoneNumberValid(validatePhoneNumber(value)); // Validate when the value changes
  };

  const handleDateChange = (date) => {
    const today = new Date();
    const minAgeDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );

    if (date === null || date <= minAgeDate) {
      setSelectedDate(date);
      setAgeMessage("");
    } else {
      setAgeMessage("Must be 18years and above.");
    }
  };

  const updateBiodata = async (e) => {
    e.preventDefault();

    setNameValid(name.trim() !== "");
    setGenderValid(gender !== "");
    setSelectedDateValid(selectedDate !== null);
    setSelectStateValid(selectState !== "");
    setSelectResidenceValid(selectResidence !== "");

    if (
      !nameValid ||
      !genderValid ||
      !selectedDateValid ||
      !selectStateValid ||
      !selectResidenceValid ||
      !phoneNumberValid
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }
    if (ageMessage) {
      toast.error("Must be 18 years and above.");
      return;
    }

    let payload = {
      name: name,
      gender: gender,
      date_of_birth: moment(selectedDate).format("YYYY-MM-DD"),
      state_of_origin_id: selectStateId,
      state_of_residence_id: selectResidenceId,
      email: email,
      phone_number: phoneNumber,
    };

    try {
      toast.loading("loading...");
      const res = await api.updateUserBiodata(payload);
      toast.remove();
      customToast.success(res.message);
      await refetch();
      await refetchStatus();
      closeModal(false);
    } catch (error) {
      if (error.res) {
        customToast.error(error.res.data.message);
      } else {
        customToast.error(error.message);
      }
    }
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50">
      <div className="">
        <div className="w-[300px] min-h-[12rem] md:w-[600px] bg-white rounded-[32px] flex flex-col justify-center p-8 md:p-12 relative">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Edit Biodata
          </h3>
          {isLoading ? (
            <div className="flex justify-center">
              <ScaleLoader color="#87AA4B" className="" />
            </div>
          ) : (
            <form onSubmit={updateBiodata}>
              <div className="mb-4 ">
                <div className="">
                  <label
                    htmlFor="name"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    Name
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  id="name"
                  className={` rounded-lg  flex-1 appearance-none border ${
                    !nameValid ? "border-red-500" : "border-lightGrey"
                  }   w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                  name="name"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValid(e.target.value.trim() !== "");
                  }}
                />
                {!nameValid && (
                  <p className="text-red-500 text-xs mt-1">Name is required</p>
                )}
              </div>
              <div className="mb-4 ">
                <label
                  htmlFor="gender"
                  className="text-gray-500 mb-6 text-base font-medium"
                >
                  Gender
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <div className="flex gap-x-20 items-center">
                  <div className="flex items-center gap-x-2">
                    <input
                      name="gender"
                      type="radio"
                      value="Male"
                      checked={gender === "Male"}
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderValid(true); // Mark gender as valid
                      }}
                      className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid ${
                        !genderValid ? "border-red-500" : "border-neutral-300"
                      } before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                    />
                    Male
                  </div>
                  <div className="flex items-center gap-x-2">
                    <input
                      name="gender"
                      type="radio"
                      value="Female"
                      className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid ${
                        !genderValid ? "border-red-500" : "border-neutral-300"
                      } before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                      checked={gender === "Female"}
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderValid(true); // Mark gender as valid
                      }}
                    />{" "}
                    Female
                  </div>
                </div>
                {!genderValid && (
                  <p className="text-red-500 text-xs mt-1">
                    Please select a gender
                  </p>
                )}
              </div>

              <div className=" flex flex-col mb-6">
                <label
                  htmlFor="date_of_birth"
                  className="text-gray-500 mb-2 text-base font-medium"
                >
                  Date of Birth
                  <span className="text-red-500 required-dot">*</span>
                </label>
                <div
                  className={`rounded-lg  flex-1  border ${
                    !selectedDateValid ? "border-red-500" : "border-lightGrey"
                  } w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                >
                  <DatePicker
                    selected={
                      selectedDate !== null ? new Date(selectedDate) : null
                    }
                    onChange={handleDateChange}
                    onSelect={setSelectedDate}
                    maxDate={new Date()}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    placeholderText="Date of birth"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                {ageMessage && (
                  <p className="text-red-500 text-xs mt-1">{ageMessage}</p>
                )}
              </div>
              <div className="md:flex gap-x-3">
                <div className="w-full md:w-1/2 mb-4 md:mb-0 ">
                  <label
                    htmlFor="state"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    State of Residence
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search state"
                    noOptionsMessage={() => "Search for state"}
                    value={
                      selectResidenceId
                        ? { value: selectResidenceId, label: selectResidence } // Provide both value and label
                        : null // No value selected initially
                    }
                    onChange={(selectedOption) => {
                      setSelectedResidenceId(selectedOption?.value || "");
                      setSelectedResidence(selectedOption?.label || "");
                      setSelectResidenceValid(!!selectedOption);
                    }}
                    className={`border rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent ${
                      !selectResidenceValid
                        ? "border-red-500 focus:ring-red-500"
                        : "border-lightGrey"
                    }`}
                  />
                  {!selectResidenceValid && (
                    <p className="text-red-500 text-xs mt-1">
                      Please select a state
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0 ">
                  <label
                    htmlFor="state"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    State of Origin
                    <span className="text-red-500 required-dot">*</span>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    placeholder="Search state"
                    noOptionsMessage={() => "Search for state"}
                    value={
                      selectStateId
                        ? { value: selectStateId, label: selectState } // Provide both value and label
                        : null // No value selected initially
                    }
                    onChange={(selectedOption) => {
                      setSelectedStateId(selectedOption?.value || "");
                      setSelectedState(selectedOption?.label || "");
                      setSelectStateValid(!!selectedOption);
                    }}
                    className={`border rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent ${
                      !selectStateValid
                        ? "border-red-500 focus:ring-red-500"
                        : "border-lightGrey"
                    }`}
                  />
                  {!selectStateValid && (
                    <p className="text-red-500 text-xs">
                      Please select a state
                    </p>
                  )}
                </div>
              </div>

              <div className="md:flex gap-x-3 mt-4">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="email"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    Email
                  </label>

                  <input
                    type="text"
                    id="email"
                    className=" rounded-lg  flex-1 appearance-none border border-lightGrey w-full py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                    name="email"
                    placeholder="Your email"
                    disabled
                    value={email}
                  />
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                  <label
                    htmlFor="phone_number"
                    className="text-gray-500 mb-6 text-base font-medium"
                  >
                    Phone
                    <span className="text-red-500 required-dot">*</span>
                  </label>

                  <PhoneInput
                    className={`rounded-lg  flex-1 appearance-none border ${
                      !phoneNumberValid ? "border-red-500" : "border-lightGrey"
                    }  w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    limitMaxLength={true}
                    international={true}
                    defaultCountry="NG"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onCountryChange={(v) => setSelectedCountry(v)}
                    placeholder="Your phone number"
                  />
                  {!phoneNumberValid && (
                    <p className="text-red-500 text-xs">
                      Please enter a valid phone number
                    </p>
                  )}
                </div>
              </div>

              <div className="flex gap-x-10 justify-center mt-10">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-primary  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm font-medium text-primary  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                  onClick={() => closeModal(false)}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-primary text-sm font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditBiodataModal;
