import axios from "axios";
import baseUrl from "../url";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && error.config.url !== "/") {
      // Clear session storage token and user
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("user");
      // Redirect to login page
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

export default client;

export const api = {
  // LOGIN
  login: (payload) =>
    client
      .post("/auth/login", { ...payload, account_type: "candidate" })
      .then(({ data }) => data),

  directLinkToProfile: (payload) =>
    client.post(`/auth/login/profile`, { ...payload }).then(({ data }) => data),

  // LOGOUT
  logout: () => client.post(`/auth/logout`).then(({ data }) => data),

  // SIGN UP OR REGISTER CANDIDATE
  signUp: (payload) =>
    client
      .post(`/auth/register?account_type=candidate`, { ...payload })
      .then(({ data }) => data),

  getProfileStatus: () =>
    client.get(`/candidate/profile/status`).then(({ data }) => data),

  getUserBiodata: () =>
    client.get(`/candidate/profile/bio-data`).then(({ data }) => data),

  getUserGroupAssesment: (id) =>
    client.get(`/candidate/assessment/${id}`).then(({ data }) => data),

  getAssesments: () => client.get(`/candidate/exams`).then(({ data }) => data),

  getPracticeTestQuestions: () =>
    client
      .get(`/candidate/exams/questions/practice-mode`)
      .then(({ data }) => data),

  getExams: (payload) =>
    client.get(`/candidate/exams`).then(({ data }) => data),

  getExamsHistory: () =>
    client.get(`/candidate/exams/history`).then(({ data }) => data),

  getExamsResult: (payload) =>
    client.get(`/candidate/exams/results/${payload}`).then(({ data }) => data),

  getExamQuestions: (payload) =>
    client
      .get(
        `/candidate/exams/${payload.groupId}/assessments/${payload.assessmentId}`
      )
      .then(({ data }) => data),

  previewExamsQuestions: (payload) =>
    client
      .get(`/candidate/exams/${payload.user_group_id}/assessments/${payload.assessment_id}/preview-answers `)
      .then(({ data }) => data),

  flagAssessment: (payload) =>
    client.post(`/flag-assessment`, { ...payload }).then(({ data }) => data),

  /* UPDATE ANSWERS */
  updateAnswers: (payload) =>  
    client    
      .post(`/candidate/exams/${payload.user_group_id}/assessments/${payload.assessment_id}`, payload.data)
      .then(({ data }) => data),

  /* GRADE ASSESSMENT */
  gradeAssessment: (payload) =>
    client    
      .post(`/candidate/exams/${payload.user_group_id}/assessments/${payload.assessment_id}/submit-assessment`)
      .then(({ data }) => data),

  // FLAG INCORRECT QUESTION
  flAgQuestion: (payload) =>
    client.post(`/flag-question/${payload}`).then(({ data }) => data),

  submitAssessmentAnswers: (payload) =>
    client.post(`/assessment/submit`, { ...payload }).then(({ data }) => data),

  // FINISH CANDIDATE EXAM
  finishExam: (payload) =>
    client
      .post(`/assessment/grade-assessment`, { ...payload })
      .then(({ data }) => data),

  // APPLICATIONS
  getApplications: () =>
    client.get(`/candidate/applications`).then((data) => data),

  changePassword: (payload) =>
    client
      .post(`/auth/update-password`, { ...payload })
      .then(({ data }) => data),

  getBookings: (group_id) =>
    client.get(`/candidate/exams/${group_id}/bookings`).then(({ data }) => data),

  // getBookings: (group_id) =>
  //   client.get(`/candidate/bookings/${group_id}`).then(({ data }) => data),

  createBooking: ({group_id, payload}) =>
    client
      .post(`/candidate/exams/${group_id}/bookings`, { ...payload })
      .then(({ data }) => data),

  unlockAssessment: (payload, group_id) =>
    client
      .post(`candidate/exams/${group_id}/unlock-exam`, { ...payload })
      .then(({ data }) => data),
  
  // unlockAssessment: (payload) =>
  //   client
  //     .post(`/candidate/bookings/unlock-assessment`, { ...payload })
  //     .then(({ data }) => data),

  updateUserBiodata: (payload) =>
    client
      .patch(`/candidate/profile/bio-data`, { ...payload })
      .then(({ data }) => data),

  createUserEducation: (payload) =>
    client
      .post(`/candidate/profile/education`, { ...payload })
      .then(({ data }) => data),

  getUserEducationList: () =>
    client.get(`/candidate/profile/education`).then(({ data }) => data),

  updateUserEducation: (id, payload) =>
    client
      .put(`/candidate/profile/education/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserEducation: (id) =>
    client
      .delete(`/candidate/profile/education/${id}`)
      .then(({ data }) => data),

  createUserExperience: (payload) =>
    client
      .post(`/candidate/profile/experience`, { ...payload })
      .then(({ data }) => data),

  getUserExperienceList: () =>
    client.get(`/candidate/profile/experience`).then(({ data }) => data),

  updateUserExperience: (id, payload) =>
    client
      .put(`/candidate/profile/experience/${id}`, { id, ...payload })
      .then(({ data }) => data),

  deleteUserExperience: (id) =>
    client
      .delete(`/candidate/profile/experience/${id}`)
      .then(({ data }) => data),

  getInstitutions: () => client.get(`/institutions`).then(({ data }) => data),

  getCourses: () => client.get(`/courses`).then(({ data }) => data),

  getStates: () => client.get(`/states`).then(({ data }) => data),

  getDegrees: () => client.get(`/degrees`).then(({ data }) => data),

  getClassOfDegrees: () =>
    client.get(`/class-of-degrees`).then(({ data }) => data),

  getVacancies: () =>
    client.get(`/candidate/applications/vacancies`).then(({ data }) => data),

  getNysc: () =>
    client.get(`/candidate/profile/nysc-data`).then(({ data }) => data),

  AddNyscData: (payload) =>
    client
      .post(`/candidate/profile/nysc-data`, { ...payload })
      .then(({ data }) => data),

  // VERIFY CANDIDATE EMAIL BEFORE APPLYING FOR JOB
  verifyJobApplicationEmail: (payload) =>
    client.post(`/verification/`, { ...payload }).then(({ data }) => data),

  // APPLYING FOR JOB
  jobApplication: (payload) =>
    client
      .post(`/candidate/applications/`, { ...payload })
      .then(({ data }) => data),

  // NOTIFICATION ENDPOINTS
  getUserNotification: () =>
    client.get(`/candidate/notifications`).then(({ data }) => data),

  // USER PROFILE ENDPOINTS
  getUserProfile: () =>
    client.get(`/candidate/profile`).then(({ data }) => data),

  // COUNT PAGE VISITOR
  countPageVisitor: (referral, payload) =>
    client
      .post(`/applications/clicks/${referral}`, { referral, ...payload })
      .then(({ data }) => data),

  // VERIFY CANDIDATE EMAIL AFTER REGISTERING TO LOGIN
  verifyRegisteredUserEmail: (id, hash) =>
    client
      .get(`/auth/email/verify/${id}/${hash}`, { id, hash })
      .then(({ data }) => data),

  resetUserPassword: (payload) =>
    client
      .post(`/auth/reset-password/`, { ...payload })
      .then(({ data }) => data),

  emailVerification: (payload) =>
    client
      .post(`/auth/email-verification`, { ...payload })
      .then(({ data }) => data),

  forgotPassword: (payload) =>
    client
      .post(`/auth/forgot-password`, {
        ...payload,
      })
      .then(({ data }) => data),

  verifyCampaignData: (payload) =>
    client
      .post(`/applications/verify-data`, { ...payload })
      .then(({ data }) => data),

  resendMail: (payload) =>
    client
      .post(`/auth/resend-verification-link`, { ...payload })
      .then(({ data }) => data),

  examVerification: (slug, payload) =>
    client
      .post(`/candidate/exams/${slug}/verification/`, { slug, ...payload })
      .then(({ data }) => data),

  registerExam: (payload) =>
    client.post(`/auth/register/exam`, { ...payload }).then(({ data }) => data),

  loginExam: (payload) =>
    client.post(`/auth/login/exam`, { ...payload }).then(({ data }) => data),

  // GET JOB LISTINGS
  getJobListings: () =>
    client.get(`/listings?per_page=5`).then(({ data }) => data),

  sendFeedback: (payload) =>
    client.post(`/feedbacks`, { ...payload }).then(({ data }) => data),
 
  getFeedbackQuestions: (payload) =>
  client.get(`/feedbacks`, { ...payload }).then(({ data }) => data),
};