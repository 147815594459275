import { createSlice } from "@reduxjs/toolkit";

const candidateExamSlice = createSlice({
    name: 'candidate exam',
    initialState: {
      timeLeft: 0,
      startedExams : [],
      finishCandidateExam: false,
      batchQuestionIds: [],
      activeExam: {
        lastQuestionViewed: 0,
        examId : 0,
      },
      examQuestions: [],
      questionSavedAnswers: [],
      flaggedQuestions: [],
      questionsDuration: [],
      failedSubmittedQuestion: [],
    },
   reducers : {
    addFailedSubmittedQuestion: (state,{payload}) => {
      const failedAnwers = payload;
      if(state.failedSubmittedQuestion.length >= 1){
        failedAnwers.forEach((failedAnwer) => {
          const existingAnswerIndex = state.failedSubmittedQuestion.findIndex(
            (answer) => answer.answer_id === failedAnwer.answer_id
          );  
          if (existingAnswerIndex !== -1) {
            // Update existing answer
            state.failedSubmittedQuestion[existingAnswerIndex] = failedAnwer;
          } else {
            // Add new answer
            state.failedSubmittedQuestion.push(failedAnwer);
          }
        });
      }
      else {         
        failedAnwers.forEach((failedAnwer) => {
          state.failedSubmittedQuestion.push(failedAnwer)
        })
      }
     },
    clearFailedQuestions: (state) => {
      state.failedSubmittedQuestion = []
    },
    updateExamQuestions: (state,{payload}) => {
      state.examQuestions = payload?.filter( question => {
        return question?.answers?.some( ans => ans?.status === true )
      })
    },
    clearExamQuestions: (state) => {
      state.examQuestions = []
    },
    addBatchQuestionIds : (state,{payload}) => {
         if(state.batchQuestionIds.length >= 1){
           const batchQuestionIndex = state.batchQuestionIds.findIndex((batchQuestion) => batchQuestion === payload);
           if (batchQuestionIndex !== -1) {
                state.batchQuestionIds[batchQuestionIndex] = payload  
           }
           else state.batchQuestionIds.push(payload)
         }
         else state.batchQuestionIds.push(payload)
     },
     clearRecentActiveExamData: (state) => {
       state.batchQuestionIds = []
       state.questionSavedAnswers = [];
       state.activeExam.lastQuestionViewed = 0;
     },
     addQuestionSavedAnswers: (state, { payload }) => {
       
      if(payload?.flat()?.length >= 1 ){
        // Flatten the payload array if it contains multiple arrays        
        const updatedAnswersArray = payload.flat();  
        updatedAnswersArray.forEach(updatedAnswer => {
          const itemIndex = state.questionSavedAnswers.findIndex(
            answer => {
                      if(
                           answer.question_type?.toLowerCase() === 'select' ||
                            answer.question_type?.toLowerCase() === 'true or false' ||
                            answer.question_type?.toLowerCase() === 'yes or no'
                          ){
                             
                            return  ( 
                              (answer.question_id === updatedAnswer.question_id)
                              )
                          }
                          else {
                            return  ( 
                              (answer.question_id === updatedAnswer.question_id) &&
                              (answer.answer_id === updatedAnswer.answer_id)
                               )
                          }
                     }
          );        
          if (itemIndex !== -1) {
            // Item exists, update it
           state.questionSavedAnswers[itemIndex] = updatedAnswer;
            
          } else {
            // Item doesn't exist, push it to the array
            state.questionSavedAnswers.push(updatedAnswer);
          }
        });
      }
    },    
     clearQuestionIds: (state) => {
       state.batchQuestionIds = [] ;
     },
     // update time left for each exam that has been started
     updateTimeLeft: (state, {payload}) => {
      
      state.activeExam.lastQuestionViewed = payload
     },
     updateActiveExam: (state,{payload}) => {
        state.activeExam.lastQuestionViewed = payload.lastQuestionViewed
        state.activeExam.examId = payload.examId;
     },
     flushSubmittedAnswers: (state) => {
       state.questionSavedAnswers = [] ;
   },
   toggleCheckedAnswer: (state,{payload}) => {
     state.questionSavedAnswers = state.questionSavedAnswers.filter( answers => answers?.answer_id !== payload )
   },
   addFlaggedQuestion: (state,{payload}) => {
    state.flaggedQuestions.push(payload)
   },
   clearFlaggedQuestion: (state,{payload}) => {
    state.flaggedQuestions = []
   },
   clearQuestionDuration: (state,{payload}) => {
    state.questionsDuration = []
   },
   addQuestionDuration: (state,{payload}) => {
        if(state.questionsDuration?.length >= 1){
          const questionIndex = state.questionsDuration.findIndex( (question) => question.id === payload?.id )
          if( questionIndex === -1 ){
             state.questionsDuration.push({...payload, visitCount: 0})             
          }
          else {    
              const question = state.questionsDuration[questionIndex];      
              if(question?.visitCount === undefined){
                  state.questionsDuration[questionIndex] = {
                    ...question,
                    duration: payload?.duration,
                }
              } 
              else {                
                    state.questionsDuration[questionIndex] = {
                     ...question, duration: question?.duration + 1
                    }
              }                 
           }
        }
        else state.questionsDuration.push({...payload, visitCount: 0 })
     },
     updateQuestionVisitCount: (state,{payload}) => {
      state.questionsDuration = state.questionsDuration.map( question => {
        if(question?.id === payload){
           return { ...question, visitCount: question.visitCount === undefined ? 0 : question.visitCount + 1 }
        }
        else return { ...question }
      })
     },
   }
})

export const {
    updateExamQuestions,
    clearExamQuestions,
    updateQuestionVisitCount,
    addFailedSubmittedQuestion,
    clearFailedQuestions,
    clearQuestionDuration,
    addQuestionDuration,
    flushSubmittedAnswers,
    toggleCheckedAnswer,
    addBatchQuestionIds,
    addQuestionSavedAnswers,
    clearQuestionIds,
    updateTimeLeft,
    updateActiveExam,
    addFlaggedQuestion,
    clearFlaggedQuestion,
    clearRecentActiveExamData,
    updateFinishCandidateExamStatus,
} = candidateExamSlice.actions

export default candidateExamSlice.reducer