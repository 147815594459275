import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import logo from "../../images/TestAssessify.png";
import { api } from "../../api";
import Input from "../../components/Global/Input";

import { useNavigate, Link, useParams } from "react-router-dom";

const ForgotPassword =  () => {
  const methods = useForm();
  const navigate = useNavigate()
  const { watch, formState: { errors } } = methods;
  const [email, setEmail] = useState("");

  const handleForgotPassword = methods.handleSubmit(async(data) => {
   
    let payload = {
      email: email,
    };
    setEmail(email);
    try {
      toast.loading("Loading...");
      const res = await api.forgotPassword(data);
      toast.remove();
      toast.success(res.message);
      navigate("/reset-password")
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.message);
    }
  });
  return (
    <div className="bg-white p-4 lg:p-10">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="flex justify-center mt-16 2xl:mt-40  2xl:h-screen">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full  md:w-[600px] lg:w-[900px] 2xl:w-[1440px] 2xl:h-[700px]  rounded-xl p-2 lg:p-10 sm:shadow-2xl ">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2 2xl:h-[400px] 2xl:mt-32  p-4 2xl:p-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0  lg:px-4">
                <div className="2xl:mb-10">
                  <p className="text-3xl text-darkBlue pt-4 mb-2 font-semibold">
                    Welcome to Test Assesify
                  </p>
                  <p className="text-base font-normal text-slate-5">
                    Enter your email address
                  </p>
                </div>
                
 <FormProvider {...methods}>
                <form className="pt-3 md:pt-4" onSubmit={(e) => e.preventDefault}>
                  <div className="flex flex-col pt-4 mb-12">
                    <div className="flex relative ">
                      <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span>
                      {/* <Input
                        autoFocus
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isIcon
                      /> */}
                      <Input
                          name="email"
                          autoFocus
                          isIcon
                          placeholder="Enter your email"
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          type="email"
                        />
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleForgotPassword}
                    className="w-full px-4 2xl:py-8 2xl:mt-10 py-4 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                  >
                    <span className="w-full text-xl 2xl:text-2xl">Submit</span>
                  </button>
                </form>
                </FormProvider>
                <div className="py-4  text-center">
                  <p className="text-xl">
                    Want to go back? &nbsp;
                    <Link to="/" className="font-semibold underline">
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
