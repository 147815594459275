import React from "react";

import { VictoryPie } from "victory";

const data = [
  {
    id: "ruby",
    label: "ruby",
    value: 404,
    color: "hsl(117, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 300,
    color: "hsl(292, 70%, 50%)",
  },
  {
    id: "java",
    label: "java",
    value: 173,
    color: "#9994a3",
  },
];

const LabelOnHover = ({ x, y, datum }) => {
  return (
    <g>
      {datum.hoverLabel && (
        <text x={x} y={y} textAnchor="middle">
          {datum.y}
        </text>
      )}
    </g>
  );
};

const MyResponsivePie = () => (
  <VictoryPie
    data={[55, 54, 54]}
    colorScale={["#f68158", "#9ab767", "#9994a3"]}
    innerRadius={100}
    labelComponent={<LabelOnHover />}
    style={{ data: { stroke: "#fff", strokeWidth: 3 } }}
  />
);

export default MyResponsivePie;
