import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery } from "react-query";
import logo from "../../images/TestAssessify.png";
import { api } from "../../api";

import SuccessfulReg from "./successfulReg";
import Input from "./../../components/Global/Input";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";

function register({ closeModal }) {
  const ref = useRef()
  const customToast = useCustomToast();
  const methods = useForm();
  const { watch, formState: { errors } } = methods;
  const location = useLocation();
  const navigate = useNavigate();
  const previousRoute = location.state?.from;
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  
  // Extract the slug and referral from url
  const slug = location.pathname.split("/")[2];
  const referral = new URLSearchParams(location.search).get("referral");

  sessionStorage.setItem("slug", slug || null);
  sessionStorage.setItem("referral", referral || null);

  let payload = {
    slug: location.pathname.split("/")[2],
    referral: new URLSearchParams(location.search).get("referral"),
  };

  if (slug && referral !== "null") {
    const { isLoading, data, isError, error } = useQuery(
      ["verify-campaign-data"],
      async () => {
        const data = await api.verifyCampaignData(payload);
        return data;
      }
    );
  }

  const resetForm = () => {
    methods.reset();
    methods.setValue("email", ""); // Reset password field
    methods.setValue("first_name", ""); // Reset password field
    methods.setValue("last_name", ""); // Reset password field
    methods.setValue("password", ""); // Reset password field
    methods.setValue("confirm_password", ""); // Reset confirm_password field
  };
  

  const Register = methods.handleSubmit(async (data) => {
    try {
      toast.loading("Loading...");
      const res = await api.signUp({
        ...data,
        slug: payload.slug,
        referral: payload.referral,
      });
      toast.remove();
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      if (
        res.data.status === "in progress" ||
        res.data.status === "pending application"
      ) {
        customToast.success(res.message);
        navigate("/email-verification")
        // setOpenSuccessfulModal(true);
          resetForm()
      } else {
        customToast.success(res.message);
        navigate("/email-verification")
          resetForm()
      }
    } catch (error) {
      toast.remove();
      customToast.error(error?.response?.data?.error[0]);
    }
  });

  const handleNavigateToLogin = () => {
    navigate("/", {
      state: { from: ["/application/:slug", "/application/:slug/:referral"] },
    });
  };

  return (
    <div className=" bg-white p-4 lg:p-6">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="flex justify-center items-center mt-20  2xl:mt-0  2xl:h-screen">
        {/* {openSuccessfulModal && <SuccessfulReg />} */}
        <div className="bg-cover bg-no-repeat w-full  bg-hero-img  md:w-[600px] lg:w-[900px] 2xl:w-[1440px]   rounded-xl p-2 lg:p-10 sm:shadow-2xl">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2  2xl:mt-20 p-4 2xl:p-6">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0  lg:px-4">
                <div className="2xl:mb-10">
                  <p className="text-3xl text-darkBlue pt-4 mb-6 font-semibold text-center">
                    Welcome to Test Assesify
                  </p>
                  <p className="text-2xl font-medium text-darkBlue mb-5">
                    How To Apply For Jobs On Test Assessify
                  </p>
                  <div>
                    <p className="mb-4">
                      {" "}
                      <span className="font-medium text-darkBlue text-xl">
                        Step 1 :
                      </span>{" "}
                      Create a profile or log in to your existing account
                    </p>
                    <p className="mb-4">
                      <span className="font-medium text-darkBlue text-xl">
                        Step 2 :
                      </span>{" "}
                      Apply for a role and submit your application.
                    </p>
                    <p className="mb-4">
                      <span className="font-medium text-darkBlue text-xl">
                        Step 3 :
                      </span>{" "}
                      Get notified and track your progress.
                    </p>
                  </div>
                  <p className="text-2xl font-medium text-darkBlue mt-4">
                    Create Account
                  </p>
                </div>
                <FormProvider {...methods}>
                  <form className="" onSubmit={(e) => e.preventDefault}>
                    <div className=" pt-4 mb-4">
                      <div className="flex relative 2xl:mb-10 group">
                        <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                          <svg
                            width="15"
                            height="15"
                            fill="currentColor"
                            viewBox="0 0 1792 1792"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                          </svg>
                        </span>
                        <Input
                          name="email"
                          // value={values.email}
                          // onChange={handleInputChange}
                          isIcon
                          placeholder="Enter your email"
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          type="email"
                          ref={(ref) => methods.register(ref)}
                        />
                      </div>
                    </div>
                    <div className="mb-4 2xl:mb-10">
                      <Input
                        // id="first_name"
                        name="first_name"
                        placeholder="Enter your first name"
                        rules={{ required: "First Name is required" }}
                        type="text"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="mb-6 2xl:mb-10">
                      <Input
                        // id="last_name"
                        name="last_name"
                        // value={values.last_name || ""}
                        placeholder="Enter your last name"
                        // onChange={handleInputChange}
                        rules={{ required: "Last Name is required" }}
                        type="text"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="mb-6 2xl:mb-10">
                      <Input
                        name="password"
                        placeholder="Enter  password"
                        rules={{ required: "Password is required" }}
                        type="password"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    <div className="mb-6 2xl:mb-10">
                      <Input
                        name="password_confirmation"
                        placeholder="Confirm password"
                        rules={{  required: "Password is required",
                        validate: (value) => value === watch("password") || "Passwords do not match", }}
                        type="password"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                    {/* {errors.confirm_password && (
          <p className="text-red-500 mt-1">{errors.confirm_password.message}</p>
        )} */}
                    <div className="sm:flex justify-between items-center">
                      <button
                        type="submit"
                        onClick={Register}
                        className="w-full sm:w-fit text-center px-6 py-4  rounded-lg text-base font-semibold  text-white transition duration-200 ease-in bg-[#88a848] shadow-md active:border-none active:outline-none focus:border-none focus:outline-none "
                      >
                        <span className="text-xl 2xl:text-2xl">Sign Up</span>
                      </button>
                      <div className="py-4  text-center 2xl:py-8">
                        <p className="text-xl">
                          Already have an account? &nbsp;
                          <button
                            type="button"
                            onClick={handleNavigateToLogin}
                            className="font-semibold underline text-primary"
                          >
                            Login
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                </FormProvider>

                {/* <div className="mt-4 ">
                  {" "}
                  <button class="w-full flex justify-center px-4 py-2 border  gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                    <img
                      class="w-6 h-6"
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>Sign up with Google</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default register;
